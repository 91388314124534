import type { MouseEvent, ReactElement } from 'react';
import { useState, memo } from 'react';
import styled, { css } from 'styled-components';
import type { RuleSet } from 'styled-components';
import isEqual from 'react-fast-compare';
import Image from 'next/legacy/image';
import { useTranslation } from 'next-i18next';

import type { ApolloError } from '@apollo/client';
import { useRouter } from 'next/router';
import type { GlobalCart as GlobalCartProps } from '../../../../amplienceTypes/schemas/imported/global-cart-schema';
import { NextLink } from '../../core/link/NextLink';
import { media, colors, animations } from '../../core/styles';
import { Typography } from '../../core/typography/Typography';
import { Button } from '../../core/button/Button';
import type { ImageEntry, CartEntry } from '../../../../utilities/graphql/codegen';
import { useRemoveFromCartMutation } from '../../../../utilities/graphql/codegen';
import { useAppContext } from '../../../../utilities/context/static/AppContext';
import { useCartContext } from '../../../../utilities/context/dynamic/CartContext';
import {
  CART_ENTRIES,
  CART_MUTATED,
  CART_STORAGE_VALUE,
  HYBRIS_CART_STORAGE_VALUE,
} from '../../../../utilities/constants/localStorageKeys';
import {
  parseCartUpdateDataLayer,
  getProductDataLayerEvent,
  triggerQubitEvent,
} from '../../../../utilities/vendors';
import { useStaticContext } from '../../../../utilities/context/static/StaticContext';
import { storageAvailable } from '../../../../utilities/helpers';
import CartUnreservedItemsComponent from './CartUnreservedItemsComponent';
import { useDataLayerContext } from '../../../../utilities/context/static/DataLayerContext';
import { useUserContext } from '../../../../utilities/context/dynamic/UserContext';
import { SiteType } from '~/utilities/graphql/codegen';
import CloseModalIcon from '../../core/icons/CloseModalIcon';
import { useConsentContext } from '~/utilities/context/dynamic/ConsentContext';

const style = {
  GStarCart: css`
    padding: 20px 25px;

    @media ${({ theme }) => media(theme).greaterThan('xl')} {
      padding: 25px 40px;
    }
  `,
  AafCart: css`
    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      padding: 0;
    }
  `,

  GStarTotalArea: css`
    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      display: block;
      padding-top: 10px;
      margin-top: 10px;
      border-top: 1px solid ${({ theme }) => theme.colors.content.component.header.accessibility};
    }
  `,

  AafTotalArea: css`
    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      padding: ${({ theme }) => theme.size[4]};
      display: flex;
      flex-direction: column;
      gap: ${({ theme }) => theme.size[2]};
    }
  `,

  GStarDesktopCart: css`
    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      display: block;
      margin-right: -10px;
      margin-top: 10px;
      text-align: left;
    }
  `,

  AafDesktopCart: css`
    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      display: flex;
      flex-direction: column;
      gap: ${({ theme }) => theme.size[2]};
      padding: ${({ theme }) => theme.size[4]};
    }
  `,

  GStarCartItem: css`
    position: relative;
    height: 90px;
    padding-left: 90px;
    padding-bottom: 10px;
    box-sizing: border-box;
  `,

  AafCartItem: css`
    position: relative;
    display: flex;
    align-items: center;
    height: 74px;
    width: 100%;
    box-sizing: border-box;
  `,

  GStarProductImage: css`
    position: absolute;
    left: 0;
  `,
  AafProductImage: css`
    position: relative;
  `,

  GStarCheckoutArea: css`
    margin-top: 25px;
    padding: 0 12px;

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      gap: 15px;
      padding: 0;
    }
  `,

  AafCheckoutArea: css`
    padding: ${({ theme }) => theme.size[4]};

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      gap: ${({ theme }) => theme.size[4]};
    }
  `,

  GStarCheckoutButton: css`
    font-size: 13px;
    text-transform: none;
    margin-bottom: 13px;
    font-weight: 600;
  `,

  AafCheckoutButton: css`
    font-size: ${({ theme }) => theme.size[3]};
    text-transform: uppercase;
    font-weight: 700;
  `,

  GStarSubtotal: css`
    color: ${({ theme }) => theme.colors.content.component.header.modal.color};
    width: 190px;
    text-transform: none;
    text-align: right;
  `,

  AafSubtotal: css`
    color: ${({ theme }) => theme.colors.content.secondary};
    width: 190px;
    text-transform: none;
    font-size: ${({ theme }) => theme.font.size[2]};
    font-style: normal;
    font-weight: 400;
    line-height: 136%;
    letter-spacing: 0.12px;
  `,

  GStarPrice: css`
    line-height: 18px;
  `,

  AafPrice: css`
    font-size: ${({ theme }) => theme.font.size[2]};
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  `,

  GStarProductTitle: css`
    line-height: 17px;
  `,

  AafProductTitle: css`
    line-height: 20px;
    font-weight: 700;
    font-size: ${({ theme }) => theme.font.size[2]};
    font-style: normal;
    text-transform: uppercase;
  `,
};

const ProductTitleStyles: Record<SiteType, RuleSet<object>> = {
  [SiteType.Gstar]: style.GStarProductTitle,
  [SiteType.Outlet]: style.GStarProductTitle,
  [SiteType.Employeeshop]: style.GStarProductTitle,
  [SiteType.Aaf]: style.AafProductTitle,
};

const PriceStyles: Record<SiteType, RuleSet<object>> = {
  [SiteType.Gstar]: style.GStarPrice,
  [SiteType.Outlet]: style.GStarPrice,
  [SiteType.Employeeshop]: style.GStarPrice,
  [SiteType.Aaf]: style.AafPrice,
};

const SubtotalStyles: Record<SiteType, RuleSet<object>> = {
  [SiteType.Gstar]: style.GStarSubtotal,
  [SiteType.Outlet]: style.GStarSubtotal,
  [SiteType.Employeeshop]: style.GStarSubtotal,
  [SiteType.Aaf]: style.AafSubtotal,
};

const TotalAreaStyles: Record<SiteType, RuleSet<object>> = {
  [SiteType.Gstar]: style.GStarTotalArea,
  [SiteType.Outlet]: style.GStarTotalArea,
  [SiteType.Employeeshop]: style.GStarTotalArea,
  [SiteType.Aaf]: style.AafTotalArea,
};

const CartStyles: Record<SiteType, RuleSet<object>> = {
  [SiteType.Gstar]: style.GStarCart,
  [SiteType.Outlet]: style.GStarCart,
  [SiteType.Employeeshop]: style.GStarCart,
  [SiteType.Aaf]: style.AafCart,
};

const CartItemStyles: Record<SiteType, RuleSet<object>> = {
  [SiteType.Gstar]: style.GStarCartItem,
  [SiteType.Outlet]: style.GStarCartItem,
  [SiteType.Employeeshop]: style.GStarCartItem,
  [SiteType.Aaf]: style.AafCartItem,
};

const DesktopCartStyles: Record<SiteType, RuleSet<object>> = {
  [SiteType.Gstar]: style.GStarDesktopCart,
  [SiteType.Outlet]: style.GStarDesktopCart,
  [SiteType.Employeeshop]: style.GStarDesktopCart,
  [SiteType.Aaf]: style.AafDesktopCart,
};

const ProductImageStyles: Record<SiteType, RuleSet<object>> = {
  [SiteType.Gstar]: style.GStarProductImage,
  [SiteType.Outlet]: style.GStarProductImage,
  [SiteType.Employeeshop]: style.GStarProductImage,
  [SiteType.Aaf]: style.AafProductImage,
};

const CheckoutAreaStyles: Record<SiteType, RuleSet<object>> = {
  [SiteType.Gstar]: style.GStarCheckoutArea,
  [SiteType.Outlet]: style.GStarCheckoutArea,
  [SiteType.Employeeshop]: style.GStarCheckoutArea,
  [SiteType.Aaf]: style.AafCheckoutArea,
};

const CheckoutButtonStyles: Record<SiteType, RuleSet<object>> = {
  [SiteType.Gstar]: style.GStarCheckoutButton,
  [SiteType.Outlet]: style.GStarCheckoutButton,
  [SiteType.Employeeshop]: style.GStarCheckoutButton,
  [SiteType.Aaf]: style.AafCheckoutButton,
};

const S = {
  Cart: styled.div<{ $hovered: boolean }>`
    display: none;
    background: ${({ theme }) => theme.colors.content.component.header.modal.base};
    position: absolute;
    text-align: center;
    right: 0;
    left: 0;
    top: var(--top-nav-height);
    z-index: -1;
    box-sizing: border-box;
    transform: ${({ $hovered }) => ($hovered ? 'translateY(0)' : 'translateY(-100%)')};

    &:focus-within {
      transform: translateY(0);
    }

    ${({ theme }) => CartStyles[theme.siteType]}

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      display: initial;
      left: unset;
      width: 480px;
      max-height: unset;
    }

    @media ${({ theme }) => media(theme).noMotionPreference} {
      transition: visibility 0.2s ease, transform 0.2s ease;
    }

    @media (max-height: 768px) {
      max-height: calc(100vh - 54px);
      overflow-y: scroll;
    }
  `,

  EmptyCart: styled.div<{ $isGStar: boolean }>`
    ${({ $isGStar }) =>
      !$isGStar &&
      css`
        padding: ${({ theme }) => theme.size[4]};
        border-top: 1px solid ${({ theme }) => theme.colors.border.subtle};
        border-bottom: 1px solid ${({ theme }) => theme.colors.border.subtle};
      `};
  `,

  EmptyTitle: styled(Typography)<{ $isGStar: boolean }>`
    color: ${({ theme }) => theme.colors.content.component.header.modal.color};
    text-transform: ${({ theme }) => (theme.siteType === SiteType.Aaf ? 'uppercase' : 'lowercase')};
    font-size: ${({ theme }) =>
      theme.siteType === SiteType.Aaf ? ({ theme }) => theme.size[4] : '13px'};
    ${({ $isGStar }) =>
      $isGStar
        ? css`
            padding: 30px 0;
          `
        : css`
            font-weight: 400;
            text-align: start;
            padding-bottom: ${({ theme }) => theme.size[4]};
          `};
  `,

  ContinueShopping: styled(Button)<{ $isGStar: boolean }>`
    position: relative;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    transition: 75ms background-color cubic-bezier(0.84, 0.12, 0.51, 0.81),
      75ms color cubic-bezier(0.84, 0.12, 0.51, 0.81),
      75ms border-color cubic-bezier(0.84, 0.12, 0.51, 0.81);
    font-size: ${({ theme }) =>
      theme.siteType === SiteType.Aaf ? ({ theme }) => theme.size[3] : '13px'};
    text-transform: ${({ theme }) => (theme.siteType === SiteType.Aaf ? 'uppercase' : 'lowercase')};
    text-decoration: none;
    ${({ $isGStar }) =>
      !$isGStar &&
      css`
        width: 100%;
      `}
  `,

  MobileCart: styled.div`
    display: block;

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      display: none;
    }
  `,

  ProductAddedTitle: styled(Typography)<{ $isGStar: boolean }>`
    color: ${({ theme }) => theme.colors.content.component.header.modal.color};
    font-weight: ${({ $isGStar }) => ($isGStar ? 700 : 400)};
    padding: 32px 0 6px;
    text-align: ${({ $isGStar }) => ($isGStar ? 'center' : 'start')};
  `,

  CheckoutArea: styled.div`
    ${({ theme }) => CheckoutAreaStyles[theme.siteType]}
    display: block;

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      display: flex;
      justify-content: space-between;
    }
  `,

  Checkout: styled(Button)`
    width: 100%;
    order: 2;
    ${({ theme }) => CheckoutButtonStyles[theme.siteType]}

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      margin: 0;
      height: fit-content;
      width: 50%;
      margin-left: auto;
    }
  `,

  RewardArea: styled.div`
    margin: 30px 0 15px;
    order: 1;

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      margin: 0;
    }
  `,

  RewardTitle: styled(Typography)`
    color: ${({ theme }) => theme.colors.content.component.header.modal.color};
    font-size: 14px;
    font-weight: 900;
    line-height: 20px;
    text-transform: uppercase;
  `,

  RewardDescription: styled(Typography)`
    color: ${({ theme }) => theme.colors.content.component.header.modal.color};
    font-weight: 400;
  `,

  SignupLink: styled(NextLink)`
    color: ${({ theme }) => theme.colors.content.component.header.accessibility};
  `,

  DesktopCart: styled.div`
    display: none;
    ${({ theme }) => DesktopCartStyles[theme.siteType]}

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      max-height: 330px;
      overflow-y: auto;
      overflow-x: hidden;
    }

    @media ${({ theme }) => media(theme).lessThan('sm')} {
      max-height: calc(100vh - 350px);
    }
  `,

  CartItem: styled(NextLink)`
    display: block;
    outline-offset: -1px;

    ${({ theme }) => CartItemStyles[theme.siteType]}
  `,

  ProductImage: styled.div`
    z-index: -1;

    ${({ theme }) => ProductImageStyles[theme.siteType]}
  `,

  ProductDescription: styled.div<{ $isGStar: boolean }>`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: ${({ $isGStar }) => ($isGStar ? 'center' : 'start')};

    ${({ $isGStar }) =>
      !$isGStar &&
      css`
        padding-left: ${({ theme }) => theme.size[2]};
      `}
  `,

  ProductInfo: styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 2px;
  `,

  ProductData: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${({ theme }) => theme.size['0-5']};
  `,

  PriceCross: styled.div<{ $isGStar: boolean }>`
    display: flex;
    align-items: flex-start;
    gap: 5px;
    ${({ $isGStar }) =>
      !$isGStar &&
      css`
        flex-direction: column;
        justify-content: space-between;
      `}
  `,

  ActualPrice: styled(Typography)<{ $isPersonalisedDiscount?: boolean }>`
    color: ${({ theme, $isPersonalisedDiscount }) =>
      $isPersonalisedDiscount
        ? colors.FREE_RETURN_FEE_GREEN
        : theme.colors.content.component.header.modal.color};

    ${({ theme }) => PriceStyles[theme.siteType]}
  `,

  PrevPrice: styled(Typography)`
    color: ${({ theme }) => theme.colors.content.component.header.accessibility};
    ${({ theme }) => PriceStyles[theme.siteType]}
  `,

  ProductTitle: styled(Typography)`
    color: ${({ theme }) => theme.colors.content.component.header.modal.color};
    ${({ theme }) => ProductTitleStyles[theme.siteType]}
  `,

  CrossIcon: styled.button`
    font-size: 13px;
    margin-top: -4px;
    color: ${({ theme }) => theme.colors.content.component.header.accessibility};

    &:hover,
    &:focus {
      color: ${({ theme }) => theme.colors.content.component.header.modal.color};
    }
  `,

  CrossIconAaf: styled.button`
    align-self: end;
    width: ${({ theme }) => theme.size[4]};
    height: ${({ theme }) => theme.size[4]};
  `,

  LoadingIcon: styled.div`
    width: ${({ theme }) => theme.size[4]};
    height: ${({ theme }) => theme.size[4]};
    background-image: var(--spinner);
    background-repeat: no-repeat;
    animation: ${animations.spin} 0.6s linear infinite;

    @media (prefers-reduced-motion) {
      animation: none;
    }
  `,

  Category: styled(Typography)`
    color: ${({ theme }) => theme.colors.content.component.header.modal.color};
    padding-top: 5px;
    font-weight: 400;
  `,

  TotalArea: styled.div`
    display: none;

    ${({ theme }) => TotalAreaStyles[theme.siteType]}
  `,

  SubArea: styled.div<{ $isGStar: boolean; $isPersonalisedDiscount?: boolean }>`
    display: flex;
    justify-content: space-between;
    ${({ $isGStar }) =>
      $isGStar &&
      css`
        padding-top: 10px;
      `}

    ${({ $isPersonalisedDiscount }) =>
      $isPersonalisedDiscount &&
      css`
        background-color: ${colors.PPE_GREEN};
        color: ${colors.FREE_RETURN_FEE_GREEN};
      `}
  `,

  Subtotal: styled(Typography)`
    ${({ theme }) => SubtotalStyles[theme.siteType]}

    .hasPersonalisedDiscount & {
      text-align: left;
    }
  `,

  Total: styled.div<{ $isGStar: boolean }>`
    ${({ $isGStar }) =>
      $isGStar &&
      css`
        margin-top: 42px;
      `}
  `,
  DescriptionParams: styled(Typography)`
    color: ${({ theme }) => theme.colors.content.component.header.modal.color};
    line-height: 136%;
    font-size: ${({ theme }) => theme.font.size[2]};
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.12px;
  `,
};

const MiniCartComponent = ({
  emptyCart,
  productAdded,
  subtotal,
  total,
  shipping,
  freeLabel,
  rawRewards,
  signUp,
  rewardText,
  continueShopping,
  sizeLabel,
  colorLabel,
  quantityLabel,
  checkout,
  discount,
  voucher,
  tax,
  oneSizeLabel,
  itemsNotReservedMessage,
}: GlobalCartProps): ReactElement => {
  const { t } = useTranslation('common', { keyPrefix: 'globalCart' });

  const { locale, country, siteType, isGStar } = useAppContext();
  const { pushToDataLayer } = useDataLayerContext();
  const { cartHovered, setCartHovered, miniCart, setMiniCart } = useCartContext();
  const { user } = useUserContext();

  const {
    configuration: { loyaltyPointSystem, enableExponea, showStrikethroughPrices },
    accessibilityLabels,
  } = useStaticContext();
  const { hasMarketingConsent, hasAnalyticsConsent } = useConsentContext();
  const [removing, setRemoving] = useState<{ [k: number]: boolean }>({});
  const [removeFromCartMutation] = useRemoveFromCartMutation();
  const router = useRouter();

  const cartProducts = miniCart.entries;
  const makeImageValue = (
    simplifiedImages?: (ImageEntry | null | undefined)[] | null,
    shouldUseDarkBackgroundImgs?: boolean | null
  ) => {
    const image = simplifiedImages?.find(img => {
      const imageKey = shouldUseDarkBackgroundImgs ? 'M01' : 'Z01';

      return img?.key === imageKey;
    });

    return {
      url: image?.value?.url?.replace('{{dimensions}}', 'h_95'),
      alt: image?.value?.altText,
    };
  };

  const removeFromCart = async (cartEntry?: CartEntry | null) => {
    if (
      !cartEntry ||
      cartEntry?.entryNumber === undefined ||
      cartEntry?.entryNumber === null ||
      Number.isNaN(Number(cartEntry?.entryNumber))
    ) {
      // TODO Error Handle Entry Number missing in the cart product response
      return;
    }

    try {
      setRemoving({ [cartEntry?.entryNumber]: true });

      const result = await removeFromCartMutation({
        variables: {
          siteType,
          entryNumber: cartEntry?.entryNumber,
          locale,
        },
      });
      const cartData = result.data;

      if (cartData?.removeFromCart?.cart) {
        if (storageAvailable('localStorage')) {
          localStorage.setItem(`${country}${CART_MUTATED}`, CART_STORAGE_VALUE);
          localStorage.setItem(
            `${CART_ENTRIES}_${locale}`,
            JSON.stringify(cartData.removeFromCart.cart)
          );
        }

        triggerQubitEvent(
          'ecBasketItemAction',
          {
            action: 'remove',
            productId: cartEntry.product?.baseProduct || cartEntry.product?.code,
            quantity: cartEntry.quantity,
          },
          hasMarketingConsent && hasAnalyticsConsent
        );

        setMiniCart(cartData.removeFromCart.cart);

        const eecRemoveFromCartEvent = getProductDataLayerEvent('eecRemoveFromCart', cartEntry);

        if (eecRemoveFromCartEvent) {
          pushToDataLayer(eecRemoveFromCartEvent);
        }

        if (enableExponea) {
          const exponeaCartUpdateProperties = parseCartUpdateDataLayer(
            'remove',
            cartData.removeFromCart,
            cartEntry.product
          );

          pushToDataLayer({
            event: 'exponea_cart_update',
            event_name: 'cart_update',
            namespace: 'exponea',
            event_properties: exponeaCartUpdateProperties,
          });
        }
      }

      setRemoving({ [cartEntry?.entryNumber]: false });
    } catch (e) {
      if ((e as ApolloError)?.message?.includes('440')) {
        if (storageAvailable('localStorage')) {
          localStorage.setItem(`${country}${CART_MUTATED}`, HYBRIS_CART_STORAGE_VALUE);
        }

        router.reload();
      } else {
        setRemoving({ [cartEntry?.entryNumber]: false });
        // eslint-disable-next-line no-console
        console.log('Error - RemoveFromCart via MiniCart', e);
      }
    }
  };

  const removeLocaleFromUrl = (url?: string | null) => {
    const splitUrl = url?.split('/');
    const modifiedUrl = splitUrl?.slice(2);

    return `/${modifiedUrl?.join('/')}`;
  };

  const emptyCartEl = (
    <S.EmptyCart $isGStar={isGStar}>
      <S.EmptyTitle
        $isGStar={isGStar}
        component="p"
        variant="h4"
        alignment="center"
        testId="mini-cart-empty-label"
      >
        {emptyCart || t('emptyCart')}
      </S.EmptyTitle>
      <S.ContinueShopping
        $isGStar={isGStar}
        ordinal="success"
        label={continueShopping || t('continueShopping')}
        href={`/${locale}`}
        testId="continue-shopping-button"
      />
    </S.EmptyCart>
  );

  const fullCartEl = (
    <>
      {/* Mobile Cart */}
      <S.MobileCart>
        <S.ProductAddedTitle component="p" variant="body" $isGStar={isGStar}>
          {productAdded || t('productAdded')}
        </S.ProductAddedTitle>
        {cartHovered && isGStar && <CartUnreservedItemsComponent text={itemsNotReservedMessage} />}
      </S.MobileCart>

      {/* Desktop Cart */}
      <S.DesktopCart>
        {cartHovered && isGStar && <CartUnreservedItemsComponent text={itemsNotReservedMessage} />}
        {cartProducts?.map((cartProduct, i) => {
          const { url, alt } = makeImageValue(
            cartProduct?.product?.simplifiedImages,
            cartProduct?.product?.shouldUseDarkBackgroundImgs
          );
          const price = isGStar
            ? cartProduct?.totalPrice?.formattedValueWithoutCurrency
            : cartProduct?.totalPrice?.formattedValue;

          return (
            <S.CartItem href={removeLocaleFromUrl(cartProduct?.product?.url)} key={i}>
              {url && alt && (
                <S.ProductImage data-testid="mini-cart-product-image">
                  <Image
                    src={url}
                    alt={alt}
                    width={isGStar ? 80 : 74}
                    height={isGStar ? 80 : 74}
                    layout="fixed"
                  />
                </S.ProductImage>
              )}
              <S.ProductDescription $isGStar={isGStar}>
                <S.ProductInfo>
                  <S.ProductData>
                    <S.ProductTitle
                      component="h5"
                      variant="h4"
                      testId="mini-cart-product-name"
                      data-cs-capture=""
                    >
                      {cartProduct?.product?.name}
                    </S.ProductTitle>
                    {!isGStar && (
                      <S.DescriptionParams
                        component="p"
                        variant="body"
                        testId="mini-cart-product-size"
                        data-cs-capture=""
                      >
                        {`${sizeLabel || t('sizeLabel') || ''} ${
                          cartProduct?.product?.oneSize
                            ? oneSizeLabel || t('oneSizeLabel')
                            : cartProduct?.product?.size?.formattedValue || ''
                        }`}
                      </S.DescriptionParams>
                    )}
                    {!isGStar && (
                      <S.DescriptionParams
                        component="p"
                        variant="body"
                        testId="mini-cart-product-color"
                        data-cs-capture=""
                      >
                        {`${colorLabel || t('colorLabel')} ${
                          cartProduct?.product?.color?.description || ''
                        }`}
                      </S.DescriptionParams>
                    )}
                    {!isGStar && (
                      <S.DescriptionParams
                        component="p"
                        variant="body"
                        testId="mini-cart-product-quantity"
                        data-cs-capture=""
                      >
                        {`${quantityLabel || t('quantityLabel') || ''} ${
                          cartProduct?.quantity || ''
                        }`}
                      </S.DescriptionParams>
                    )}
                  </S.ProductData>
                  <S.PriceCross $isGStar={isGStar}>
                    {!isGStar &&
                      (cartProduct &&
                      cartProduct.entryNumber !== undefined &&
                      cartProduct.entryNumber !== null &&
                      !Number.isNaN(Number(cartProduct.entryNumber)) &&
                      removing[cartProduct.entryNumber] ? (
                        <S.LoadingIcon />
                      ) : (
                        <S.CrossIconAaf
                          aria-label={accessibilityLabels?.removeFromCart}
                          onClick={(e: MouseEvent) => {
                            e.preventDefault();
                            e.stopPropagation();
                            removeFromCart(cartProduct);
                          }}
                          data-testid="mini-cart-remove-product"
                          data-cs-capture=""
                        >
                          <CloseModalIcon />
                        </S.CrossIconAaf>
                      ))}
                    <div>
                      {showStrikethroughPrices &&
                        cartProduct?.totalPrice?.value !==
                          cartProduct?.totalPriceWithoutDiscount?.value && (
                          <S.PrevPrice
                            component="p"
                            variant={isGStar ? 'h5' : 'body'}
                            decoration="line-through"
                            testId="mini-cart-before-price"
                            data-cs-capture=""
                          >
                            {isGStar
                              ? cartProduct?.totalPriceWithoutDiscount
                                  ?.formattedValueWithoutCurrency
                              : cartProduct?.totalPriceWithoutDiscount?.formattedValue}
                          </S.PrevPrice>
                        )}
                      <S.ActualPrice
                        component="p"
                        variant={isGStar ? 'h5' : 'body'}
                        testId="mini-cart-product-price"
                        data-cs-capture=""
                      >
                        {cartProduct?.gift ? freeLabel || t('freeLabel') : price}
                      </S.ActualPrice>
                    </div>

                    {isGStar &&
                      (cartProduct &&
                      cartProduct.entryNumber !== undefined &&
                      cartProduct.entryNumber !== null &&
                      !Number.isNaN(Number(cartProduct.entryNumber)) &&
                      removing[cartProduct.entryNumber] ? (
                        <S.LoadingIcon />
                      ) : (
                        <S.CrossIcon
                          aria-label={accessibilityLabels?.removeFromCart}
                          onClick={(e: MouseEvent) => {
                            e.preventDefault();
                            e.stopPropagation();
                            removeFromCart(cartProduct);
                          }}
                          data-testid="mini-cart-remove-product"
                          data-cs-capture=""
                        >
                          &#215;
                        </S.CrossIcon>
                      ))}
                  </S.PriceCross>
                </S.ProductInfo>
                {isGStar && (
                  <S.Category
                    component="p"
                    variant="body"
                    testId="mini-cart-product-category"
                    data-cs-capture=""
                  >
                    {`${cartProduct?.product?.materialGroup} / ${
                      cartProduct?.product?.color?.description
                    } / ${
                      cartProduct?.product?.oneSize
                        ? oneSizeLabel || t('oneSizeLabel')
                        : cartProduct?.product?.size?.formattedValue
                    } / ${cartProduct?.quantity}`}
                  </S.Category>
                )}
              </S.ProductDescription>
            </S.CartItem>
          );
        })}
      </S.DesktopCart>

      <S.TotalArea className={user?.personalisedDiscount ? 'hasPersonalisedDiscount' : ''}>
        <S.SubArea $isGStar={isGStar}>
          <S.Subtotal component="span" variant="body">
            {subtotal || t('subtotal')}
          </S.Subtotal>
          <S.ActualPrice
            component="span"
            variant={isGStar ? 'h5' : 'body'}
            testId="mini-cart-subtotal"
            data-cs-capture=""
          >
            {isGStar
              ? miniCart.subTotal?.formattedValueWithoutCurrency
              : miniCart.subTotal?.formattedValue}
          </S.ActualPrice>
        </S.SubArea>
        {(miniCart.orderDiscounts?.value ?? 0) > 0 && (
          <S.SubArea $isGStar={isGStar} $isPersonalisedDiscount={!!user?.personalisedDiscount}>
            <S.Subtotal component="span" variant="body">
              {discount || t('discount')}
            </S.Subtotal>
            <S.ActualPrice
              component="span"
              variant={isGStar ? 'h5' : 'body'}
              testId="mini-cart-discount"
              data-cs-capture=""
              $isPersonalisedDiscount={!!user?.personalisedDiscount}
            >
              -
              {isGStar
                ? miniCart.orderDiscounts?.formattedValueWithoutCurrency
                : miniCart.orderDiscounts?.formattedValue}
            </S.ActualPrice>
          </S.SubArea>
        )}
        {(miniCart.voucherTotalDiscount?.value ?? 0) > 0 && (
          <S.SubArea $isGStar={isGStar} $isPersonalisedDiscount={!!user?.personalisedDiscount}>
            <S.Subtotal component="span" variant="body">
              {voucher || t('voucher')}
            </S.Subtotal>
            <S.ActualPrice
              component="span"
              variant={isGStar ? 'h5' : 'body'}
              testId="mini-cart-voucher"
              data-cs-capture=""
              $isPersonalisedDiscount={!!user?.personalisedDiscount}
            >
              -
              {isGStar
                ? miniCart.voucherTotalDiscount?.formattedValueWithoutCurrency
                : miniCart.voucherTotalDiscount?.formattedValue}
            </S.ActualPrice>
          </S.SubArea>
        )}
        <S.SubArea $isGStar={isGStar}>
          <S.Subtotal component="span" variant="body">
            {shipping || t('shipping')}
          </S.Subtotal>
          <S.ActualPrice
            component="span"
            variant={isGStar ? 'h5' : 'body'}
            testId="mini-cart-shipping"
            data-cs-capture=""
          >
            {miniCart.deliveryCost?.value && miniCart.deliveryCost?.value > 0
              ? miniCart.deliveryCost?.formattedValueWithoutCurrency
              : freeLabel || t('freeLabel')}
          </S.ActualPrice>
        </S.SubArea>
        {miniCart.externalTaxEnabled && (
          <S.SubArea $isGStar={isGStar}>
            <S.Subtotal component="span" variant="body">
              {tax || t('tax')}
            </S.Subtotal>
            <S.ActualPrice
              component="span"
              variant={isGStar ? 'h5' : 'body'}
              testId="mini-cart-tax"
              data-cs-capture=""
            >
              {isGStar
                ? miniCart.totalTax?.formattedValueWithoutCurrency
                : miniCart.totalTax?.formattedValue}
            </S.ActualPrice>
          </S.SubArea>
        )}
        <S.Total $isGStar={isGStar}>
          <S.SubArea $isGStar={isGStar}>
            <S.Subtotal component="span" variant="body">
              {total || t('total')}
            </S.Subtotal>
            <S.ActualPrice
              component="span"
              variant={isGStar ? 'h5' : 'body'}
              testId="mini-cart-total"
              data-cs-capture=""
            >
              {miniCart.totalPrice?.formattedValue}
            </S.ActualPrice>
          </S.SubArea>
        </S.Total>
      </S.TotalArea>

      <S.CheckoutArea>
        <S.Checkout
          label={checkout || t('checkout')}
          ordinal="success"
          href={`/${locale}/checkout/shopping-bag`}
          testId="mini-cart-checkout"
          data-cs-capture=""
          hardReload
        />

        {!miniCart.customer?.loyaltySubscribed && loyaltyPointSystem === 'POINT_SYSTEM_GLOBAL' && (
          <S.RewardArea data-testid="mini-cart-rewards">
            <S.RewardTitle component="p" variant="h3" color={colors.PRODUCT_BLUE}>
              {rawRewards || t('rawRewards')}
            </S.RewardTitle>
            <S.RewardDescription component="p" variant="body">
              <S.SignupLink href="/account" testId="mini-cart-rewards-sign-up" data-cs-capture="">
                {signUp || t('signUp')}
              </S.SignupLink>{' '}
              {rewardText || t('rewardText')}
            </S.RewardDescription>
          </S.RewardArea>
        )}
      </S.CheckoutArea>
    </>
  );

  return (
    <S.Cart
      $hovered={cartHovered}
      onMouseEnter={() => setCartHovered(true)}
      onMouseLeave={() => setCartHovered(false)}
      data-testid="mini-cart"
      id="cartHeader"
      data-cs-capture=""
    >
      {miniCart.totalItems && miniCart.totalItems > 0 ? fullCartEl : emptyCartEl}
    </S.Cart>
  );
};

export const MiniCart = memo(MiniCartComponent, isEqual);
