import styled, { css } from 'styled-components';
import { colors, media, spacing } from '../core/styles';
import type { Maybe, Scalars } from '../../../utilities/graphql/codegen';
import { CookieWallVersion } from '../../../utilities/graphql/codegen';
import { Typography } from '../core/typography/Typography';
import { Button } from '../core/button/Button';
import { Switch } from '../core/form';
import { SiteType } from '~/utilities/graphql/codegen';

const cookieV1CSS = css<{ $settingsOpen: boolean }>`
  margin: 0 10px;
  padding: 30px;
  max-width: 960px;

  @media ${({ theme }) => media(theme).greaterThan('sm')} {
    max-width: ${({ $settingsOpen }) => ($settingsOpen ? '455px' : '240px')};
    margin: 0 auto;
  }
`;

const cookieV2CSS = css<{ $settingsOpen: boolean }>`
  margin: auto auto 0;
  padding: 16px;
  max-width: 960px;

  @media ${({ theme }) => media(theme).greaterThan('sm')} {
    max-width: ${({ $settingsOpen }) => ($settingsOpen ? '455px' : '701px')};
    margin: auto auto 0;
  }
`;

const cookieV3CSS = css<{ $settingsOpen: boolean }>`
  margin: auto auto 0;
  padding: 16px;
  max-width: unset;

  @media ${({ theme }) => media(theme).greaterThan('sm')} {
    max-width: ${({ $settingsOpen }) => ($settingsOpen ? '455px' : 'unset')};
    padding: 16px 64px;
  }

  @media ${({ theme }) => media(theme).greaterThan('xxxl')} {
    padding: 16px 96px;
  }
`;

export const S = {
  CookieBackdrop: styled.div`
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 10018;
    backdrop-filter: blur(2px);
  `,
  CookieWrapper: styled.div<{
    $enableCookieWallV1?: Maybe<Scalars['Boolean']>;
  }>`
    position: fixed;
    inset: 0;
    top: ${({ $enableCookieWallV1 }) => ($enableCookieWallV1 ? '0' : 'unset')};
    display: flex;
    align-items: ${({ $enableCookieWallV1 }) => ($enableCookieWallV1 ? 'center' : 'flex-end')};
    justify-content: center;
    min-height: auto;
    overflow-y: auto;
    z-index: 10019;

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      padding-block: ${({ $enableCookieWallV1 }) => ($enableCookieWallV1 ? '34px' : '34px 0')};
      align-items: flex-end;
    }
  `,

  Cookie: styled.div<{
    $settingsOpen: boolean;
    $cookieWallVersion?: Maybe<CookieWallVersion>;
  }>`
    width: 100%;
    background-color: ${colors.WHITE};
    border: 1px solid ${colors.BORDER_GREY};
    ${({ $cookieWallVersion }) => {
      if ($cookieWallVersion === CookieWallVersion.V1) {
        return cookieV1CSS;
      }

      if ($cookieWallVersion === CookieWallVersion.V2) {
        return cookieV2CSS;
      }

      return cookieV3CSS;
    }}
  `,

  CookieTitle: styled(Typography)`
    margin-bottom: 25px;
    font-size: 21px;
    line-height: 1em;
    text-transform: none;

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      margin-bottom: 30px;
    }
  `,

  SettingsCookieContent: styled(Typography)`
    margin-bottom: 13px;
  `,

  YesToAllBtn: styled(Button)`
    width: 100%;
    order: 2;

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      order: 1;
    }
  `,

  ExternalLink: styled.a`
    text-decoration: underline;
    color: ${colors.BLUE_CHARCOAL};

    &:hover,
    &:focus {
      color: ${colors.SUCCESS_BLUE};
    }
  `,

  Subtitle: styled(Typography)`
    margin: 10px 0;
    font-size: 13px;
    font-weight: 400;
    line-height: 1em;
    text-transform: none;
  `,

  SettingGroup: styled.div`
    padding-top: 10px;
    display: flex;
  `,

  OnOffSwitch: styled.div`
    flex: 0 0 50px;
  `,

  Switch: styled(Switch)`
    margin-top: -3px;
  `,

  CheckMark: styled.div`
    width: 18px;
    height: 18px;
    margin: auto;
  `,

  OnOff: styled(Typography)`
    margin: 0 10px;
    min-width: 30px;
    font-weight: 700;

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      margin: 0 20px;
    }
  `,

  Buttons: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 15px;
    margin-top: 15px;

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      flex-direction: row;
    }
  `,

  Link: styled.a`
    text-decoration: underline;
    color: ${colors.ACCESSIBILITY_GREY};
  `,

  YesBtn: styled(Button)`
    width: 100%;
    order: 1;

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      order: 2;
    }
  `,
  CookieV2Title: styled(Typography)`
    font-size: 21px;
    font-weight: 800;
    line-height: 26px;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 5px;

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      margin-bottom: 10px;
    }
  `,

  CookieContent: styled(Typography)`
    margin-bottom: 13px;

    & :not(:last-child) {
      margin-bottom: 20px;
    }

    @media ${({ theme }) => media(theme).lessThan('sm')} {
      overflow: auto;
      background: linear-gradient(${colors.WHITE} 33%, rgba(255 255 255 / 0%)),
        linear-gradient(rgba(255 255 255 / 0%), ${colors.WHITE} 66%) 0 100%,
        radial-gradient(farthest-side at 50% 0, rgba(84 84 84 / 40%), rgba(0 0 0 / 0%)),
        radial-gradient(farthest-side at 50% 100%, rgba(84 84 84 / 40%), rgba(0 0 0 / 0%)) 0 100%;
      background-color: ${colors.WHITE};
      background-repeat: no-repeat;
      background-attachment: local, local, scroll, scroll;
      background-size: 100% 66px, 100% 66px, 100% 22px, 100% 22px;
    }
  `,

  Text: styled.div`
    font-size: 12px;
    line-height: 16px;

    & * {
      color: ${colors.FIORD_GREY};
      font-family: ${({ theme }) => theme.font.family.primary}, Arial, Helvetica, sans-serif !important;
    }
  `,

  LinkV2: styled.a`
    text-decoration: underline;
    color: ${colors.BLUE_CHARCOAL};

    &:hover,
    &:focus {
      color: ${colors.SUCCESS_BLUE};
    }
  `,

  ButtonsV2: styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: ${spacing.XXXS};
    justify-content: space-between;
    padding-top: 11px;

    @media (max-width: 519px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  `,

  ButtonsV3: styled.div`
    display: flex;
    flex-wrap: nowrap;
    gap: ${spacing.XXXS};
    padding-top: 11px;

    @media ${({ theme }) => media(theme).lessThan('sm')} {
      flex-wrap: wrap;
    }
  `,

  PreferencesButton: styled.button`
    font-weight: 400;
    color: ${colors.BLUE_CHARCOAL};
    text-transform: none;
    text-decoration: underline;
    display: flex;
    align-items: center;
    gap: ${spacing.XXXS};
    min-width: 0;
    padding: 0 8px;
    border: none;
    cursor: pointer;

    &:hover,
    &:focus &:hover svg {
      color: ${colors.SUCCESS_BLUE};
      stroke: ${colors.SUCCESS_BLUE};
    }

    @media (max-width: 519px) {
      padding-top: 18px;
      order: 2;
    }
  `,

  IconWrapper: styled.span`
    display: block;
    width: 16px;
    height: 16px;

    svg {
      display: block;
      height: 16px;
      width: 16px;
    }
  `,

  QuickButtons: styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    justify-content: end;
    gap: ${spacing.XXXS};

    @media (max-width: 519px) {
      flex-direction: column-reverse;
    }
  `,

  CookieButton: styled(Button)`
    font-size: 14px;
    min-height: 42px;

    &.button--secondary {
      font-weight: 700;
      color: ${colors.BLUE_CHARCOAL};
      text-transform: none;
      line-height: 20px;
      min-width: 0;
      padding: 10px 16px;
      border: 1px solid ${colors.BLUE_CHARCOAL};
      transition: background-color 0.2s, color 0.2s ease;

      @media (prefers-reduced-motion) {
        transition: none;
      }

      &:hover,
      &:focus {
        color: ${colors.WHITE};
        background-color: ${({ theme }) =>
          theme.siteType === SiteType.Aaf ? '#0d0d0b' : colors.SUCCESS_BLUE};
        border: 1px solid
          ${({ theme }) => (theme.siteType === SiteType.Aaf ? '#0d0d0b' : colors.SUCCESS_BLUE)};
      }
    }

    &.button--success {
      text-transform: unset;

      &:focus {
        background-color: ${({ theme }) =>
          theme.siteType === SiteType.Aaf ? '#0d0d0b' : colors.SUCCESS_BLUE};
        border: 2px solid
          ${({ theme }) => (theme.siteType === SiteType.Aaf ? '#0d0d0b' : colors.SUCCESS_BLUE)};
        color: ${colors.WHITE};
      }
    }
  `,
  CookieV3ButtonWrapper: styled.div<{ $align?: string; $order?: string; $mobileOrder?: string }>`
    flex-grow: ${({ $align }) => ($align ? 1 : 'unset')};
    text-align: ${({ $align }) => $align ?? 'unset'};
    order: ${({ $order }) => $order ?? 'unset'};
    min-height: 44px;

    button {
      min-width: 240px !important;
      line-height: unset;
    }

    @media ${({ theme }) => media(theme).lessThan('sm')} {
      order: ${({ $mobileOrder }) => $mobileOrder ?? 'inherit'};
      width: ${({ $mobileOrder }) =>
        $mobileOrder === '1' ? '100%' : `calc(50% - ${spacing.XXXS})`};

      &:first-child {
        width: ${({ $mobileOrder }) => ($mobileOrder ? '' : '100%')};
      }

      button {
        padding: 0 ${spacing.XXXS} !important;
        width: 100%;
        height: 100%;
        min-width: unset !important;
      }
    }
  `,
};
