/* eslint-disable no-underscore-dangle */
import Script from 'next/script';
import { setCookie } from 'cookies-next';
import { Environments } from '~/utilities/constants';
import { useAppContext } from '~/utilities/context/static/AppContext';
import { useCookieContext } from '~/utilities/context/dynamic/CookieContext';
import { COCO, COCO_COOKIES_EXPIRATION } from '~/utilities/constants/cookies';

type OneTrustProps = {
  cmpDomainId: string | null;
};

type OneTrustEvent = {
  isTrusted: boolean;
  bubbles: boolean;
  cancelBubble: boolean;
  cancelable: boolean;
  composed: boolean;
  defaultPrevented: boolean;
  detail: string[];
  eventPhase: number;
  returnValue: boolean;
  timeStamp: number;
  type: 'consent.onetrust';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

const isProduction = process.env.ENVIRONMENT === Environments.PRD;

export const OneTrust = ({ cmpDomainId }: OneTrustProps) => {
  const { language } = useAppContext();
  const { cocoCookie, setHasInteracted } = useCookieContext();

  if (typeof window.OptanonWrapper !== 'function') {
    window.OptanonWrapper = function OptanonWrapper() {
      const domainData = window.OneTrust.GetDomainData();
      const IS_NOTICE_ONLY = domainData?.ConsentModel?.Name === 'notice only';

      if (IS_NOTICE_ONLY) {
        setHasInteracted(true);

        return;
      }

      window.OneTrust?.OnConsentChanged((event: OneTrustEvent) => {
        const NECESSARY = 1;
        const FUNCTIONAL = 2;
        let STATISTICS = 0;
        let MARKETING = 0;
        let SOCIAL = 0;

        if (!event.detail || !event.detail.length) {
          return;
        }

        if (event.detail.includes('C0002')) {
          STATISTICS = 4;
        }

        if (event.detail.includes('C0004')) {
          MARKETING = 8;
          SOCIAL = 16;
        }

        // eslint-disable-next-line no-bitwise
        const cocoValue = (NECESSARY | FUNCTIONAL | STATISTICS | MARKETING | SOCIAL)
          .toString(2)
          .padStart(5, '0');

        if (cocoValue && !cocoCookie) {
          setCookie(COCO, cocoValue, { expires: COCO_COOKIES_EXPIRATION, secure: true, path: '/' });
          setHasInteracted(true);
        }
      });
    };
  }

  return (
    <>
      <Script
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        data-domain-script={`${cmpDomainId}${isProduction ? '' : '-test'}`}
        data-language={language}
      />
      <Script id="coco-to-onetrust" strategy="afterInteractive">{`(function(w,d) {
      function checkExistingCocoCookie() {
        const cocoValue = '${String(cocoCookie)}';
        if (cocoValue) {
          if (cocoValue.startsWith('111')) {
            OneTrust.AllowAll(); 
          } else if (cocoValue.startsWith('110')) {
            OneTrust.UpdateConsent("Category", "C0002:0");
            OneTrust.UpdateConsent("Category", "C0004:1");
          } else if (cocoValue.startsWith('001')) {
            OneTrust.UpdateConsent("Category", "C0002:1");
            OneTrust.UpdateConsent("Category", "C0004:0");
          } else {
              
            OneTrust.RejectAll();
          }
        }
        w.__coco_processed = true;
        return;
      }

      w.__coco_processed = false;
      if (!/OptanonConsent/.test(d.cookie) && /coco/.test(d.cookie)) {
        w.addEventListener('consent.onetrust', function() {
          if (w.__coco_processed) {
            return;
          }

          checkExistingCocoCookie();
        });
      }
    }(window,document))`}</Script>
    </>
  );
};
